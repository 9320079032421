import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InquiriesConfigurationInquiryFormFragment = { __typename?: 'InquiryForm', apiKey: string, sendConfirmationEmail: boolean, confirmationEmail?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, options?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null };

export type InquiriesConfiguration_GetInquiryFormQueryVariables = Types.Exact<{
  where: Types.OrganizationWhereUniqueInput;
}>;


export type InquiriesConfiguration_GetInquiryFormQuery = { __typename?: 'Query', getInquiryForm?: { __typename?: 'InquiryForm', apiKey: string, sendConfirmationEmail: boolean, confirmationEmail?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, options?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null } | null };

export type InquiriesConfiguration_CreateInquiryFormMutationVariables = Types.Exact<{
  data: Types.InquiryFormDataInput;
}>;


export type InquiriesConfiguration_CreateInquiryFormMutation = { __typename?: 'Mutation', createInquiryForm: { __typename?: 'InquiryForm', apiKey: string, sendConfirmationEmail: boolean, confirmationEmail?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, options?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null } };

export type InquiriesConfiguration_UpdateInquiryFormMutationVariables = Types.Exact<{
  data: Types.InquiryFormDataInput;
}>;


export type InquiriesConfiguration_UpdateInquiryFormMutation = { __typename?: 'Mutation', updateInquiryForm: { __typename?: 'InquiryForm', apiKey: string, sendConfirmationEmail: boolean, confirmationEmail?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, options?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null } };

export const InquiriesConfigurationInquiryFormFragmentDoc = gql`
    fragment inquiriesConfigurationInquiryForm on InquiryForm {
  apiKey
  sendConfirmationEmail
  confirmationEmail
  fields {
    order
    type
    label
    required
    options
    multiple
  }
  styles {
    type
    value
  }
}
    `;
export const InquiriesConfiguration_GetInquiryFormDocument = gql`
    query InquiriesConfiguration_GetInquiryForm($where: OrganizationWhereUniqueInput!) {
  getInquiryForm(where: $where) {
    ...inquiriesConfigurationInquiryForm
  }
}
    ${InquiriesConfigurationInquiryFormFragmentDoc}`;

/**
 * __useInquiriesConfiguration_GetInquiryFormQuery__
 *
 * To run a query within a React component, call `useInquiriesConfiguration_GetInquiryFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfiguration_GetInquiryFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesConfiguration_GetInquiryFormQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInquiriesConfiguration_GetInquiryFormQuery(baseOptions: Apollo.QueryHookOptions<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>(InquiriesConfiguration_GetInquiryFormDocument, options);
      }
export function useInquiriesConfiguration_GetInquiryFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>(InquiriesConfiguration_GetInquiryFormDocument, options);
        }
export type InquiriesConfiguration_GetInquiryFormQueryHookResult = ReturnType<typeof useInquiriesConfiguration_GetInquiryFormQuery>;
export type InquiriesConfiguration_GetInquiryFormLazyQueryHookResult = ReturnType<typeof useInquiriesConfiguration_GetInquiryFormLazyQuery>;
export type InquiriesConfiguration_GetInquiryFormQueryResult = Apollo.QueryResult<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>;
export const InquiriesConfiguration_CreateInquiryFormDocument = gql`
    mutation InquiriesConfiguration_CreateInquiryForm($data: InquiryFormDataInput!) {
  createInquiryForm(data: $data) {
    ...inquiriesConfigurationInquiryForm
  }
}
    ${InquiriesConfigurationInquiryFormFragmentDoc}`;
export type InquiriesConfiguration_CreateInquiryFormMutationFn = Apollo.MutationFunction<InquiriesConfiguration_CreateInquiryFormMutation, InquiriesConfiguration_CreateInquiryFormMutationVariables>;

/**
 * __useInquiriesConfiguration_CreateInquiryFormMutation__
 *
 * To run a mutation, you first call `useInquiriesConfiguration_CreateInquiryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfiguration_CreateInquiryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiriesConfigurationCreateInquiryFormMutation, { data, loading, error }] = useInquiriesConfiguration_CreateInquiryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInquiriesConfiguration_CreateInquiryFormMutation(baseOptions?: Apollo.MutationHookOptions<InquiriesConfiguration_CreateInquiryFormMutation, InquiriesConfiguration_CreateInquiryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquiriesConfiguration_CreateInquiryFormMutation, InquiriesConfiguration_CreateInquiryFormMutationVariables>(InquiriesConfiguration_CreateInquiryFormDocument, options);
      }
export type InquiriesConfiguration_CreateInquiryFormMutationHookResult = ReturnType<typeof useInquiriesConfiguration_CreateInquiryFormMutation>;
export type InquiriesConfiguration_CreateInquiryFormMutationResult = Apollo.MutationResult<InquiriesConfiguration_CreateInquiryFormMutation>;
export type InquiriesConfiguration_CreateInquiryFormMutationOptions = Apollo.BaseMutationOptions<InquiriesConfiguration_CreateInquiryFormMutation, InquiriesConfiguration_CreateInquiryFormMutationVariables>;
export const InquiriesConfiguration_UpdateInquiryFormDocument = gql`
    mutation InquiriesConfiguration_UpdateInquiryForm($data: InquiryFormDataInput!) {
  updateInquiryForm(data: $data) {
    ...inquiriesConfigurationInquiryForm
  }
}
    ${InquiriesConfigurationInquiryFormFragmentDoc}`;
export type InquiriesConfiguration_UpdateInquiryFormMutationFn = Apollo.MutationFunction<InquiriesConfiguration_UpdateInquiryFormMutation, InquiriesConfiguration_UpdateInquiryFormMutationVariables>;

/**
 * __useInquiriesConfiguration_UpdateInquiryFormMutation__
 *
 * To run a mutation, you first call `useInquiriesConfiguration_UpdateInquiryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfiguration_UpdateInquiryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiriesConfigurationUpdateInquiryFormMutation, { data, loading, error }] = useInquiriesConfiguration_UpdateInquiryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInquiriesConfiguration_UpdateInquiryFormMutation(baseOptions?: Apollo.MutationHookOptions<InquiriesConfiguration_UpdateInquiryFormMutation, InquiriesConfiguration_UpdateInquiryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquiriesConfiguration_UpdateInquiryFormMutation, InquiriesConfiguration_UpdateInquiryFormMutationVariables>(InquiriesConfiguration_UpdateInquiryFormDocument, options);
      }
export type InquiriesConfiguration_UpdateInquiryFormMutationHookResult = ReturnType<typeof useInquiriesConfiguration_UpdateInquiryFormMutation>;
export type InquiriesConfiguration_UpdateInquiryFormMutationResult = Apollo.MutationResult<InquiriesConfiguration_UpdateInquiryFormMutation>;
export type InquiriesConfiguration_UpdateInquiryFormMutationOptions = Apollo.BaseMutationOptions<InquiriesConfiguration_UpdateInquiryFormMutation, InquiriesConfiguration_UpdateInquiryFormMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    