import * as Types from '../../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventNotifications_DismissAllEventsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EventNotifications_DismissAllEventsMutation = { __typename?: 'Mutation', dismissAllEvents: boolean };


export const EventNotifications_DismissAllEventsDocument = gql`
    mutation EventNotifications_DismissAllEvents {
  dismissAllEvents
}
    `;
export type EventNotifications_DismissAllEventsMutationFn = Apollo.MutationFunction<EventNotifications_DismissAllEventsMutation, EventNotifications_DismissAllEventsMutationVariables>;

/**
 * __useEventNotifications_DismissAllEventsMutation__
 *
 * To run a mutation, you first call `useEventNotifications_DismissAllEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventNotifications_DismissAllEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventNotificationsDismissAllEventsMutation, { data, loading, error }] = useEventNotifications_DismissAllEventsMutation({
 *   variables: {
 *   },
 * });
 */
export function useEventNotifications_DismissAllEventsMutation(baseOptions?: Apollo.MutationHookOptions<EventNotifications_DismissAllEventsMutation, EventNotifications_DismissAllEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventNotifications_DismissAllEventsMutation, EventNotifications_DismissAllEventsMutationVariables>(EventNotifications_DismissAllEventsDocument, options);
      }
export type EventNotifications_DismissAllEventsMutationHookResult = ReturnType<typeof useEventNotifications_DismissAllEventsMutation>;
export type EventNotifications_DismissAllEventsMutationResult = Apollo.MutationResult<EventNotifications_DismissAllEventsMutation>;
export type EventNotifications_DismissAllEventsMutationOptions = Apollo.BaseMutationOptions<EventNotifications_DismissAllEventsMutation, EventNotifications_DismissAllEventsMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    