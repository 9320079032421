import { Box, Divider, List, Typography } from '@mui/material';
import React from 'react';
import {
	InquiriesConfigurationFormStandardField,
	itemIsStandardField,
	InquiriesFormSimpleItem,
} from './InquiriesConfigurationFormFields';
import styles from './InquiriesConfigurationForm.module.scss';
import classNames from 'classnames';
import { InquiriesConfigurationFormOptionsField } from './InquiriesConfigurationFormFields/InquiriesConfigurationFormOptionsField';
import MuiTipTap from '../../../mui/MuiTipTap';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { InquiryFormFieldType } from '../../../types/generated';
import { useInquiriesConfigurationFields } from './useInquiriesConfigurationFields';

export type InquiriesConfigurationFormProps = Omit<
ReturnType<typeof useInquiriesConfigurationFields>,
'onNext' | 'confirmationEmailValidation'
>;

export const InquiriesConfigurationForm: React.FC<
InquiriesConfigurationFormProps
> = ( { handleToggle, availableItems, usedItems, onDragEnd, validate } ) => {
	const instructionsAvailableField = availableItems.find(
		( field ) => field.type === InquiryFormFieldType.Instructions
	);
	const instructionsUsedField = usedItems.find(
		( field ) => field.type === InquiryFormFieldType.Instructions
	);
	const indexOffset = instructionsUsedField ? 1 : 0;
	return (
		<Box className={ styles.container }>
			<Box className={ styles.column }>
				<Typography variant='h6'>Available fields</Typography>
				<List className={ styles.list } disablePadding data-cy='available-fields'>
					{ instructionsAvailableField ? (
						<InquiriesFormSimpleItem
							{ ...instructionsAvailableField }
							onToggle={ handleToggle }
						/>
					) : null }
					{ availableItems
						.filter(
							( item ) =>
								item.type !== InquiryFormFieldType.LastNameTwo &&
								item.type !== InquiryFormFieldType.Instructions
						)
						.map( ( item ) => (
							<InquiriesFormSimpleItem
								key={ item.type }
								{ ...item }
								onToggle={ handleToggle }
							/>
						) ) }
				</List>
			</Box>
			<Divider orientation='vertical' />
			<Box className={ styles.column }>
				<Typography variant='h6'>Layout</Typography>
				{ instructionsUsedField ? (
					<MuiTipTap
						fullWidth
						label='Type instructions here'
						value={ instructionsUsedField.label }
						onChange={ instructionsUsedField.setLabel }
						countCharacters
						maxLength={ 1000 }
						placeholder='Please contact us directly or fill out the form below, and one of our team members will be in touch shortly!'
						errorText={ instructionsUsedField.validation }
						onBlur={ validate }
					/>
				) : null }
				<DragDropContext onDragEnd={ onDragEnd }>
					<Droppable droppableId='configuration'>
						{ ( provided ) => (
							<List
								disablePadding
								className={ classNames( styles.list, styles.large ) }
								{ ...provided.droppableProps }
								ref={ provided.innerRef }
								data-cy='layout'
							>
								{ usedItems
									.sort( ( a, b ) => a.order - b.order )
									.filter(
										( field ) => field.type !== InquiryFormFieldType.Instructions
									)
									.map( ( item, index ) =>
										itemIsStandardField( item ) ? (
											<InquiriesConfigurationFormStandardField
												key={ item.type }
												{ ...item }
												index={ index + indexOffset }
											/>
										) : (
											<InquiriesConfigurationFormOptionsField
												key={ item.type }
												{ ...item }
												index={ index + indexOffset }
											/>
										)
									) }
								{ provided.placeholder }
							</List>
						) }
					</Droppable>
				</DragDropContext>
			</Box>
		</Box>
	);
};
