import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isOrgUser } from '../lib/helpers/index';
import { logout } from '../redux/actions';
import { Routes } from './routes';

export default function useProfileLinks() {
	const dispatch = useDispatch();
	const user = useSelector( ( state: any ) => state.user );

	const items = useMemo( () => {
		return [
			{
				id: 'profile',
				label: 'Profile',
				href: Routes.Profile,
			},
			{
				id: 'admin',
				label: 'Admin',
				href: Routes.Admin,
				isVisible: isOrgUser( user ) && user.isAdmin,
			},
			{
				id: 'wallet',
				label: 'My Wallet',
				href: Routes.Wallet,
			},
			{
				id: 'signout',
				label: 'Sign Out',
				onClick: () => {
					dispatch( logout() );
				},
			},
		];
	}, [ user ] );

	return items;
}
