import { isDevelopment } from '../../lib/constants';

export const getLoadInquiryFormJavaScript = () => {
	const environment = isDevelopment
		? `/${ process.env.NEXT_PUBLIC_ENVIRONMENT }`
		: '';
	const hostname =
		typeof process.env.NEXT_PUBLIC_ENVIRONMENT === 'string' &&
		[ 'PRODUCTION', 'DEMO' ].includes( process.env.NEXT_PUBLIC_ENVIRONMENT )
			? 'inquiries.rockpapercoin.com'
			: 'inquiries-dev.rockpapercoin.com';
	const formURI = `//${ hostname }${ environment }`;
	return `(n=>{var e=new XMLHttpRequest,a=document.createElement("progress"),t=(a.setAttribute("aria-label","Content loading…"),document.getElementById("inquiry-form"));t&&(t.appendChild(a),e.addEventListener("progress",function(e){e.lengthComputable&&(e.total,a.setAttribute("value",e.loaded),a.setAttribute("max",e.total))},!1)),e.addEventListener("load",function(e){var e=e.target,t=document.createElement("script");t.innerHTML=e.responseText,t.dataset.host=n,document.documentElement.appendChild(t),t.addEventListener("load",function(){a.remove()})},!1),e.open("GET",n+"/index.js?cb="+(new Date).getTime(),!0),e.send()})('${ formURI }');`;
};

export const getHTML = (
	apiURL: string,
	apiKey: string,
	organizationId: string
) =>
	`<div id="inquiry-form" ${
		isDevelopment ? `data-api="${ apiURL }"` : ''
	} data-api-key="${ apiKey }" data-organization-id="${ organizationId }"></div>`;
