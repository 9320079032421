import { gql } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	FlattenedUser,
	getAuthedUserFlattenedUserFieldsCalculatedOrgUserFragment,
	UserRegistration,
} from '../../../types/user';
import { errorMessageIncludesExpectedMessageByKeys } from './helpers';
import {
	useOrgOnboarding_GetAuthedUserLazyQuery,
	useOrgOnboarding_GetCouponDetailsLazyQuery,
	useOrgOnboarding_MarkUserOnboardingCompleteMutation,
	useOrgOnboarding_RegisterCouponUsageMutation,
	useOrgOnboarding_UpdateOrgUserExposureMutation,
} from './__generated__/useFinishUp';
import rpcShared from '@rockpapercoin/rpc-shared';
import { showError } from '../../Toast';
import { ReferralCategory, UserType } from '../../../types/generated';
import { login as reduxLogin } from '../../../redux/actions';
import { useOrgOnboarding_LoginLazyQuery } from '../__generated__/OrgOnboarding';
import { intercomData, setCookie } from '../../../lib/helpers';
import {
	COMPLETED_ONBOARDING,
	segmentEvent,
	segmentIdentify,
} from '../../../lib/helpers/segment';
import { useRouter } from 'next/router';
import { client } from '../../../context/ApolloContextProvider/client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCouponDetailsQuery = gql`
	query OrgOnboarding_getCouponDetails($where: CouponByNameInput!) {
		getCouponDetails(where: $where) {
			id
			active
			percent_off
			duration_in_months
			duration
			planCost {
				price
				proration
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const registerCouponUsageMutation = gql`
	mutation OrgOnboarding_registerCouponUsage(
		$data: RegisterCouponUsageWhere!
		$where: OrganizationWhereUniqueInput!
	) {
		registerCouponUsage(data: $data, where: $where) {
			id
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateOrgUserMutation = gql`
	mutation OrgOnboarding_updateOrgUserExposure(
		$data: OrgUserUpdateCustomInput!
		$where: OrgUserWhereUniqueInput!
	) {
		updateOrgUser(data: $data, where: $where) {
			id
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markUserOnboardingCompleteMutation = gql`
	mutation OrgOnboarding_markUserOnboardingComplete {
		markUserOnboardingComplete
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginQuery = gql`
	query OrgOnboarding_login($data: UserLoginInput!) {
		login(data: $data) {
			token
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAuthedUserQuery = gql`
	query OrgOnboarding_getAuthedUser($where: UserWhereUniqueInput!) {
		getAuthedUser(where: $where) {
			userType
			orgUser {
				...getAuthedUserFlattenedUserFieldsCalculatedOrgUser
			}
			groups {
				id
				name
			}
		}
	}
	${ getAuthedUserFlattenedUserFieldsCalculatedOrgUserFragment }
`;

const useFinishUp = ( planId?: string ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const userRegistration: UserRegistration = useSelector(
		( state: any ) => state?.userRegistration
	);
	const loggedInOrgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;
	const dispatch = useDispatch();
	const router = useRouter();

	const [ categoryExposure, setCategoryExposure ] = useState<
	ReferralCategory | undefined
	>();
	const [ otherExposure, setOtherExposure ] = useState<string | undefined>();
	const [ promoCode, setPromoCode ] = useState( userRegistration?.promoCode );
	const [ promoCodeVerbiage, setPromoCodeVerbiage ] = useState<
	string | undefined
	>();
	const [ categoryExposureValidation, setCategoryExposureValidation ] = useState<
	string | undefined
	>();
	const [ promoCodeValidation, setPromoCodeValidation ] = useState<
	string | undefined
	>();

	// API calls
	const [ getCouponDetails, { loading: promoCodeVerificationLoading } ] =
		useOrgOnboarding_GetCouponDetailsLazyQuery();
	const [ updateOrgUser ] = useOrgOnboarding_UpdateOrgUserExposureMutation();
	const [ registerCouponUsage ] = useOrgOnboarding_RegisterCouponUsageMutation();
	const [ markUserOnboardingComplete ] =
		useOrgOnboarding_MarkUserOnboardingCompleteMutation();
	const [ login ] = useOrgOnboarding_LoginLazyQuery();
	const [ getAuthedUser ] = useOrgOnboarding_GetAuthedUserLazyQuery();

	// If we change from Other to anything else and otherExposure is set, un-set it
	useEffect( () => {
		if (
			categoryExposure &&
			categoryExposure !== ReferralCategory.Other &&
			otherExposure
		) {
			setOtherExposure( undefined );
		}
	}, [ categoryExposure, otherExposure ] );

	const onBlurValidationEnabledRef = useRef( false );
	const onBlurValidationEnabled = onBlurValidationEnabledRef.current;

	const onBack = useCallback( () => {
		onBlurValidationEnabledRef.current = false;
	}, [] );

	/* Helper to get data and update UI, to support multiple needs for coupon data
	returns undefined for no coupon, false for error, otherwise returns coupon data */
	const getPromoCodeData = useCallback( async () => {
		if ( promoCode && loggedInOrgUser ) {
			const promoCodeResponse = await getCouponDetails( {
				variables: {
					where: {
						name: promoCode || '',
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						organizationId: loggedInOrgUser.organization.id,
						planId,
					},
				},
			} );
			if ( promoCodeResponse.error ) {
				// If it's an error we expect, show it in-page, otherwise show it as a PUN
				const inPageMessageKeys = [
					'promoCodeInvalid',
					'couponExpired',
					'couponNotFound',
					'couponAlreadyApplied',
					'couponCanOnlyBeAppliedBySuper',
				] as Array<keyof typeof rpcShared.strings.errorMessages>;
				if (
					errorMessageIncludesExpectedMessageByKeys(
						inPageMessageKeys,
						promoCodeResponse.error.message
					)
				) {
					setPromoCodeValidation(
						promoCodeResponse.error.message.replace( 'Validation Error: ', '' )
					);
				} else {
					setPromoCodeValidation( undefined );
					showError( promoCodeResponse.error );
				}
				setPromoCodeVerbiage( undefined );
				return false;
			} else if ( promoCodeResponse.data?.getCouponDetails ) {
				setPromoCodeValidation( undefined ); // clear any previous validation errors
				const coupon = promoCodeResponse.data.getCouponDetails;
				setPromoCodeValidation( undefined );
				setPromoCodeVerbiage(
					`${ coupon.percent_off }% off ${
						coupon.duration_in_months
							? `for ${ coupon.duration_in_months } months`
							: coupon.duration
					}`
				);
				return coupon;
			}
		} else {
			setPromoCodeValidation( undefined ); // clear any previous validation errors
			setPromoCodeVerbiage( undefined );
		}
	}, [
		promoCode,
		loggedInOrgUser,
		getCouponDetails,
		planId
	] );

	const verifyPromoCode = useCallback( async () => {
		const coupon = await getPromoCodeData();
		if ( coupon === false ) {
			// false here means there was an error
			return false;
		} else {
			// coupon here means there was a valid coupon, or no coupon also equals validity
			return coupon;
		}
	}, [ getPromoCodeData ] );

	/* If this component mounts and a promo code exists in
	userRegistration, meaning it was included in the initial /SignUp
	page path, verify it at component mount */
	useEffect( () => {
		verifyPromoCode();
		/* intentionally leaving out verifyPromoCode from the dependency array
		to avoid this useEffect firing every time promoCode changes */
	}, [ userRegistration?.promoCode, verifyPromoCode ] );

	const onValidate = useCallback(
		async ( force = false ) => {
			const categoryExposureErrors =
				categoryExposure === ReferralCategory.Other && !otherExposure
					? 'Please, let us know how you found us!'
					: undefined;
			if ( onBlurValidationEnabled || force === true ) {
				setCategoryExposureValidation( categoryExposureErrors );
			}
			if (
				typeof categoryExposureErrors === 'string' ||
				( await verifyPromoCode() ) === false // to avoid waiting a render-cycle
			) {
				return false;
			} else {
				return true;
			}
		},
		[
			categoryExposure,
			onBlurValidationEnabled,
			otherExposure,
			verifyPromoCode
		]
	);

	const logInUserIfNecessary = useCallback( async () => {
		if ( userRegistration?.coreUserFields ) {
			const result = await login( {
				variables: {
					data: {
						email: userRegistration?.coreUserFields?.email || '',
						password: userRegistration?.coreUserFields?.password || '',
					},
				},
			} );
			if ( result.error ) {
				if (
					result.error.message.includes( 'Invalid Password' ) ||
					result.error.message.includes( 'No such user found' )
				) {
					showError( 'You entered an incorrect email, password, or both.' );
				} else {
					showError( result.error );
				}
			} else if ( result.data?.login?.token ) {
				setCookie( {
					cookieName: 'Authorization',
					cookieString: result.data.login.token,
				} );
				return result.data.login.token;
			}
		}
	}, [ login, userRegistration?.coreUserFields ] );

	const authenticateUser = useCallback( async () => {
		const token = await logInUserIfNecessary();
		const response = await getAuthedUser( {
			variables: {
				where: {
					email:
						userRegistration?.coreUserFields?.email ||
						loggedInOrgUser?.user.email,
				},
			},
		} );
		if ( response.error ) {
			showError( response.error );
		} else if ( response.data?.getAuthedUser ) {
			const unflattenedUser = response.data?.getAuthedUser;
			const partiallyFlattenedUser = {
				...unflattenedUser.orgUser,
				groups: unflattenedUser?.groups,
			};
			if ( token ) {
				dispatch( reduxLogin( partiallyFlattenedUser, token ) );
			}
			const intercomUserData = {
				...partiallyFlattenedUser,
				...( promoCode ? { promo: promoCode } : undefined ),
			};
			intercomData( intercomUserData );
			segmentIdentify( partiallyFlattenedUser );
			segmentEvent( COMPLETED_ONBOARDING, {
				user: unflattenedUser.orgUser?.user,
			} );
		}
	}, [
		dispatch,
		getAuthedUser,
		logInUserIfNecessary,
		loggedInOrgUser?.user.email,
		promoCode,
		userRegistration?.coreUserFields?.email,
	] );

	const onNext = useCallback( async () => {
		onBlurValidationEnabledRef.current = true;
		const validationResult = await onValidate( true );
		if ( !validationResult || !loggedInOrgUser?.id ) {
			return false;
		}
		// record exposure data, if supplied
		if ( categoryExposure ) {
			const updateOrgUserResponse = await updateOrgUser( {
				variables: {
					where: { id: loggedInOrgUser.id },
					data: {
						referral: {
							category: categoryExposure,
							other: otherExposure,
						},
					},
				},
			} );
			if ( updateOrgUserResponse.errors ) {
				showError( updateOrgUserResponse.errors );
				return false;
			}
		}
		// if a valid promocode code exists, add it. And if it's 100% off forever, create a subscription as well
		const coupon = await getPromoCodeData();
		if ( coupon ) {
			const registerCouponUsageResponse = await registerCouponUsage( {
				variables: {
					where: { id: loggedInOrgUser.organization.id },
					data: {
						coupon: { id: coupon.id },
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					},
				},
			} );
			if ( registerCouponUsageResponse.errors ) {
				registerCouponUsageResponse.errors.forEach( ( error ) => showError( error ) );
				return false;
			}
		}
		// mark onboarding complete - we've collected everything the DB needs
		const markUserOnboardingCompleteResponse =
			await markUserOnboardingComplete();
		if ( markUserOnboardingCompleteResponse.errors ) {
			markUserOnboardingCompleteResponse.errors.forEach( ( error ) =>
				showError( error )
			);
			return false;
		}
		// update the redux user
		await authenticateUser();
		// Send the user to the dashboard
		await router.push( '/' );
		await client.refetchQueries( { include: [ 'orgOnboardingModal_GetOrgUser' ] } );
	}, [
		authenticateUser,
		categoryExposure,
		getPromoCodeData,
		loggedInOrgUser?.id,
		loggedInOrgUser?.organization?.id,
		markUserOnboardingComplete,
		onValidate,
		otherExposure,
		registerCouponUsage,
		router,
		updateOrgUser,
	] );

	return {
		categoryExposure,
		setCategoryExposure,
		categoryExposureValidation,
		otherExposure,
		setOtherExposure,
		promoCode,
		setPromoCode,
		verifyPromoCode,
		promoCodeValidation,
		promoCodeVerificationLoading,
		promoCodeVerbiage,
		onNext,
		onBack,
		onValidate,
	};
};

export default useFinishUp;
