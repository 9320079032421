import { toast } from 'react-toastify';
import styled from 'styled-components';

import P from '../elements/Paragraph';
import Icon from '../elements/icons';

const ToastWrapper = styled.div`
	&.toast {
		display: flex;
		flex-direction: row;
		.toast__icon {
			width: 1.5rem;
			margin-right: 0.5rem;
			svg {
				height: 1.5rem;
				width: 1.5rem;
			}
			&--error {
				svg {
						fill: ${ ( props ) => props.theme.error };
						opacity: 1;
					}
			}
			&--success {
				svg {
						fill: ${ ( props ) => props.theme.green };
						opacity: 1;
					}
			}
			&--warning {
				svg {
						fill: ${ ( props ) => props.theme.pink };
						opacity: 1;
					}
			}
			}
		}
	}
`;
/**
 *
 * @param { String } type error or success
 * @param { String || Object } content the content of the message
 * @param { String } icon the name of the icon to use in the message
 *
 * @returns { Object } html markup for the message
 */
const createToastMessage = ( type, content, icon ) => (
	<ToastWrapper className={ `toast toast-${ type }` }>
		<div className={ `toast__icon toast__icon--${ type }` }>
			<Icon type={ icon } />
		</div>
		<div className={ `toast__message--${ type }` }>{ content }</div>
	</ToastWrapper>
);

/**
 * wraps a string in a javascript P tag.
 *
 * @param { String } messageString
 * @param { String | undefined } key
 *
 * @returns { Object } a P component
 */
const wrapMessageString = ( messageString, key ) => {
	if ( key ) {
		return (
			<P key={ key } className='p--secondary'>
				{ messageString }
			</P>
		);
	}
	return <P className='p--secondary'>{ messageString }</P>;
};

/**
 * If a component is passed in with the error object, this method will return that component.
 * Otherwise, it will check to see if the received error is a Validation Error. If it is, it
 * will slice "Validation Error:" off of the error message and return the new string. If there
 * is no received error, it will fall back to the default error.
 *
 * @param { Object | String[] | Error[] | String | undefined } [ receivedError ] - The received error message
 *
 * @returns { Object } - Returns the error string or html object
 */
const generateErrorString = ( receivedError ) => {
	const validationText = 'Validation Error:';
	const defaultError = 'Something went wrong, please try again later.';
	/**
	 * One-off utility for handling error strings
	 * @param {string|undefined} str
	 * @returns {string}
	 */
	const processErrorString = ( str ) =>
		str && typeof str === 'string' && str.indexOf( validationText ) > -1
			? wrapMessageString( str.substring( validationText.length ) )
			: wrapMessageString( str );

	// if nothing is passed in, return the default error
	if ( !receivedError ) {
		return wrapMessageString( defaultError );
	}

	// if this is just a string, then it is made by front end, deliberately
	if ( typeof receivedError === 'string' ) {
		return processErrorString( receivedError );
	}

	// similarly, if it is a react object, it is probably here intentionally
	if ( typeof receivedError === 'object' && 'props' in receivedError ) {
		return receivedError;
	}

	// if it is a non-empty array...
	if ( Array.isArray( receivedError ) && receivedError.length > 0 ) {
		// ...of Errors
		if ( receivedError[ 0 ] instanceof Error || 'message' in receivedError[ 0 ] ) {
			return receivedError.map( ( str, i ) =>
				processErrorString( str.message, `wrongWayToKey${ i }` )
			);
		}
		// ... of strings
		if ( typeof receivedError[ 0 ] === 'string' ) {
			return receivedError.map( ( str, i ) =>
				processErrorString( str, `wrongWayToKey${ i }` )
			);
		}
	}

	if ( receivedError.message ) {
		return processErrorString( receivedError.message );
	}

	// otherwise, since we don't know how to handle this, return default error
	return processErrorString( defaultError );
};

/**
 * a wrapper for creating an error toast
 *
 * @param { String | Object | undefined } [ content ] the content of the message
 * @param { String } icon the name of the icon to use in the message, defaults to warning
 * @param { Int } autoClose the number of milliseconds to display the message
 */
export const showError = ( content, icon = 'warning', autoClose = 7000 ) => {
	if (
		content &&
		content instanceof Error &&
		content.message === 'Socket closed'
	) {
		// ignore websocket closed errors
	} else {
		const errorToDisplay = generateErrorString( content );
		const ErrorMessage = createToastMessage( 'error', errorToDisplay, icon );

		toast( ErrorMessage, { autoClose } );
	}
};

/**
 * a wrapper for creating a warning toast
 *
 * @param { String | Object } content the content of the message
 * @param { String } icon the name of the icon to use in the message, defaults to warning
 * @param { Int } autoClose the number of milliseconds to display the message
 */
export const showWarning = ( content, icon = 'warning', autoClose = 3000 ) => {
	const warningToDisplay = generateErrorString( content );
	const WarningMessage = createToastMessage( 'warning', warningToDisplay, icon );

	toast( WarningMessage, { autoClose } );
};

/**
 * a wrapper for creating a success toast
 *
 * @param { string | object } content the content of the message
 * @param { string } icon the name of the icon to use in the message, defaults to check
 * @param { int } autoClose the number of milliseconds to display the message
 */
export const showSuccess = (
	content = 'Success!',
	icon = 'check',
	autoClose = 3000
) => {
	const wrappedMessage = wrapMessageString( content );

	const SuccessMessage = createToastMessage( 'success', wrappedMessage, icon );

	toast( SuccessMessage, { autoClose } );
};
