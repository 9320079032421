import {
	Badge,
	Box,
	Button,
	IconButton,
	Paper,
	Popover,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../elements/icons';
import { useNewEventsSubscription } from '../../../../hooks/useNewEventsSubscription';
import { FlattenedUser } from '../../../../types/user';
import { Events } from '../../../Events';
import styles from './EventNotifications.module.scss';
import MuiModal from '../../../../mui/MuiModal/MuiModal';
import { gql } from '@apollo/client';
import MuiButton from '../../../../mui/MuiButton';
import { useEventNotifications_DismissAllEventsMutation } from './__generated__/';
import { showError, showSuccess } from '../../../Toast';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dismissAllEventsMutation = gql`
	mutation EventNotifications_DismissAllEvents {
		dismissAllEvents
	}
`;

export const EventNotifications: React.FC = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const [ open, setOpen ] = useState( false );
	const [ showDismissModal, setShowDismissModal ] = useState( false );
	const ref = useRef<HTMLDivElement>( null );
	const [ dismissAllEvents, { loading } ] =
		useEventNotifications_DismissAllEventsMutation();

	const { badgeContent, reset } = useNewEventsSubscription();
	useEffect( () => {
		if ( open ) {
			reset();
		}
	}, [ open, reset ] );

	if ( !user?.isLoggedIn ) {
		return null;
	}

	const handleClick = async () => {
		const res = await dismissAllEvents();
		if ( res.errors ) {
			res.errors.map( ( error ) => showError( error.message ) );
		} else if ( res.data?.dismissAllEvents ) {
			showSuccess( 'Events dismissed' );
		}
		setShowDismissModal( false );
		setOpen( false );
	};

	return (
		<Box ref={ ref } className={ styles.container }>
			<IconButton onClick={ () => setOpen( true ) }>
				<Badge
					badgeContent={ badgeContent }
					color='primary'
					className={ styles.badge }
					anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
				>
					<Icon type='bell-off' className={ styles.icon } />
				</Badge>
			</IconButton>
			<Popover
				open={ open }
				onClose={ () => setOpen( false ) }
				anchorEl={ ref.current }
				anchorOrigin={ {
					vertical: 'bottom',
					horizontal: 'right',
				} }
				transformOrigin={ {
					vertical: 'top',
					horizontal: 'right',
				} }
				keepMounted
			>
				<Paper className={ styles.paper }>
					<Typography variant='h6'>Activity Feed</Typography>
					<Button
						onClick={ () => setShowDismissModal( true ) }
						fullWidth
						variant='text'
						color='secondary'
						startIcon={ <Icon type='trash' /> }
					>
						Dismiss all
					</Button>
					<Events hideActionButtons open={ open } />
				</Paper>
			</Popover>
			<MuiModal
				muiModalProps={ {
					open: showDismissModal,
					onClose: () => setShowDismissModal( false ),
				} }
				title='Dismiss All Events'
				description='Are you sure you want to dismiss all events? Certain events cannot be dismissed'
				controls={
					<MuiButton
						onClick={ () => handleClick() }
						data-cy='dismiss-all-events-button'
						loading={ loading }
					>
						Yes
					</MuiButton>
				}
			></MuiModal>
		</Box>
	);
};
