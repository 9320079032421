import { gql } from '@apollo/client';
import { UserType, State, Country } from '../generated';

export const getAuthedUserFlattenedUserFieldsCalculatedOrgUserFragment = gql`
	fragment getAuthedUserFlattenedUserFieldsCalculatedOrgUser on CalculatedOrgUser {
		id
		phone
		isAdmin
		isOwner
		firstName
		lastName
		image
		updatedAt
		user {
			id
			userType
			email
			notificationPreference
		}
		organization {
			id
			createdAt
			name
			website
			phone
			contactEmail
			description
			image
			brandedEmails
			automatedRemindersEnabled
			invoicesAllowGratuity
			updatedAt
			facebook
			instagram
			twitter
			pinterest
			groups {
				id
				name
			}
			services {
				id
				name
			}
		}
	}
`;

export const getAuthedUserFlattenedUserFieldsFragment = gql`
	fragment getAuthedUserFlattenedUserFields on AuthedUser {
		userType
		clientUser {
			id
			description
			firstNameOne
			lastNameOne
			firstNameTwo
			lastNameTwo
			image
			updatedAt
			facebook
			instagram
			twitter
			pinterest
			weddingDate
			venue
			user {
				id
				userType
				email
				notificationPreference
			}
			assignedPlanner {
				id
				firstName
				lastName
				updatedAt
				image
				organization {
					id
					name
					updatedAt
					image
				}
			}
		}
		orgUser {
			...getAuthedUserFlattenedUserFieldsCalculatedOrgUser
		}
		superAdmin {
			id
			firstName
			lastName
			image
			updatedAt
			user {
				id
				userType
				email
			}
		}
		groups {
			id
			name
		}
	}
	${ getAuthedUserFlattenedUserFieldsCalculatedOrgUserFragment }
`;

/** @deprecated the FlattenedUser is probably a better definition here until we can move that flattenuser and user in redux stuff to typescript */
export type UserFlattened = {
	id: string;
	userType: UserType;
	isAdmin: boolean;
	isSuperAdmin: boolean;
	isOwner: boolean;
	isLoggedIn: true;
	groups?: { id: string; name: string }[];
};

export type OrgUser = {
	id: string;
	isLoggedIn: true;
	userType: UserType.OrgUser;
	updatedAt: string;
	image: string;
	phone: string | null;
	organization: {
		id: string;
		updatedAt: string;
		image: string;
		contactEmail: string;
		website: string | null;
		facebook: string | null;
		instagram: string | null;
		twitter: string | null;
		pinterest: string | null;
		name: string;
		description: string | null;
		brandedEmails: boolean;
		invoicesAllowGratuity: boolean;
		defaultOrgUser: { id: string };
		users: { id: string }[];
		automatedRemindersEnabled: boolean;
		hasStripeVerificationNeeds: boolean;
		services: { id: string; name: string }[];
		addressLine1: string;
		addressLine2?: string;
		city: string;
		state: State;
		country: Country;
		postalCode: string;
		phone?: string;
		coupon?: { id: string };
		rootFolder: { id: string }; // for creating new folders
		adminUsers: { id: string }[];
		groups: { id: string; name: string }[];
	};
	isAdmin: boolean;
	isOwner: boolean;
	firstName: string;
	lastName: string;
	contacts: any[];
	user: {
		id: string;
		email: string;
		userType: UserType.OrgUser;
		notificationPreference: string;
	};
	groups?: Array<string>;
};

export type ClientUser = {
	id: string;
	isLoggedIn: true;
	userType: UserType.ClientUser;
	updatedAt: string;
	image: string;
	phone: string | null;
	description: string | null;
	firstNameOne: string;
	lastNameOne: string;
	firstNameTwo: string | null;
	lastNameTwo: string | null;
	weddingDate: string | null;
	venue: string | null;
	addressLine1: string;
	addressLine2?: string;
	city: string;
	state: State;
	country: Country;
	postalCode: string;
	facebook: string | null;
	instagram: string | null;
	twitter: string | null;
	pinterest: string | null;
	user: {
		id: string;
		email: string;
		userType: UserType.ClientUser;
		notificationPreference: string;
	};
	assignedPlanner?: {
		id: string;
		firstName: string;
		lastName: string;
		image: string;
		updatedAt: string;
		organization: {
			id: string;
			users: { id: string }[];
			services: { id: string; name: string }[];
		};
	};
	groups?: Array<string>;
};

export type SuperAdmin = {
	id: string;
	isLoggedIn: true;
	userType: UserType.SuperAdmin;
	isSuperAdmin: boolean;
	user: {
		id: string;
		email: string;
		userType: UserType.SuperAdmin;
		notificationPreference: string;
	};
	groups?: Array<string>;
};

export type GuestUser = { isLoggedIn: false };

export type FlattenedUser =
	| OrgUser
	| ClientUser
	| SuperAdmin
	| GuestUser
	| undefined;

export const isOrgUserTypeguard = ( user: FlattenedUser ): user is OrgUser =>
	( user && user.isLoggedIn && user.userType === UserType.OrgUser ) || false;

export type UserRegistration =
	| {
		coreUserFields?: {
			email?: string;
			password?: string;
			repassword?: string;
		};
		promoCode?: string;
		inProgress: boolean;
		invitation?: {
			id: string;
			invitationType: 'NewOrg' | 'NewOrgMember' | 'NewClient';
		};
		verificationToken?: {
			email?: string;
			id?: string;
		};
	  }
	| undefined;
